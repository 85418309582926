import {Modal} from 'bootstrap';

const defaultSelector = '[data-toggle="lightbox"]';

class Lightbox {
    modalElement!: HTMLElement;
    modal: Modal;
    src: string;

    constructor(
        private el: HTMLElement,
        private modalOptions: Modal.Options = Modal.Default
    ) {
        this.src = this.getSrc(el);
        this.buildeModal();
    }

    show(): void {
        if (!this.modalElement) {
            return;
        }
        document.body.appendChild(this.modalElement);
        this.modal.show();
    }

    hide(): void {
        this.modal.hide();
    }

    getSrc(el: HTMLElement): string {
        let src = el.dataset.src || el.dataset.remote || (el as HTMLLinkElement).href || '';
        if (el.dataset.type === 'html') {
            return src;
        }
        if (!/:\/\//.test(src)) {
            src = window.location.origin + src;
        }
        const url = new URL(src);
        const caption = el.dataset.footer || el.dataset.caption
        if (caption) {
            url.searchParams.set('caption', caption);
        }
        return url.toString();
    }

    isVideo(src: string): boolean {
        return /\.mp4/i.test(src);
    }

    buildeModal(): void {
        const template = document.createElement('template');
        template.innerHTML = `
			<div class="modal lightbox" tabindex="-1" role="dialog">
				<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 90vw; max-height: 90vh;">
					<div class="modal-content border-0 bg-transparent">
						<div class="modal-body">
							<button type="button" class="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close" style="z-index: 2; background: none; top: -30px; right: 0;"><i class="icon-ion-close-circled" style="color: #fff; font-size: 28px;"></i></button>
							<div class="ratio ratio-16x9" style="background-color: #000; display: block; max-width: 90vw; max-height: 90vh;">${this.generateContent(this.src)}</div>
						</div>
					</div>
				</div>
			</div>`.trim();
        this.modalElement = template.content.firstChild as HTMLElement;
        if (!this.modalElement) {
            return;
        }
        this.modalElement.addEventListener('hidden.bs.modal', () => this.modalElement.remove());
        const dismiss = this.modalElement.querySelector('[data-bs-dismiss]');
        if (dismiss) {
            dismiss.addEventListener('click', () => this.modal.hide());
        }
        this.modal = new Modal(this.modalElement, this.modalOptions);
    }

    generateContent(src: string): string {
        let inner: string = '';
        if (this.isVideo(src)) {
            inner = `<video autoplay loop playsinline controls><source src="${src}" type="video/mp4">Your browser does not support the video tag.</video>`;
        } else {
            inner = `<img src="${src}" class="d-block mw-100 mh-100 h-auto w-auto m-auto top-0 end-0 bottom-0 start-0 img-fluid" style="z-index: 1; object-fit: contain;" />`;
        }
        return inner;
    }
}

document.querySelectorAll(defaultSelector).forEach((el: HTMLElement) => {
    el.addEventListener('click', (e) => {
        e.preventDefault();
        const lightbox = new Lightbox(el);
        lightbox.show();
    });
});
